import { Fragment, useEffect, useRef, useState, useMemo } from 'react'
import { useTranslation } from "react-i18next";
import { AudioVisualizer } from 'react-audio-visualize';
import Crunker from "crunker";
import numeral from "numeral";
import download from 'downloadjs';
// import _ from "lodash";

import AudioCombinerAddPad from './AudioCombinerAddPad.jsx';
import AudioCombinerFileUpload from './AudioCombinerFileUpload.jsx';
import Loader from '../../Loader/Loader.jsx';

import { useStore } from '../../../hook-store/store.js';
import { sleep } from '../../../utils/ui/ui-util.js';

import { marks } from '../../../utils/marks.jsx';
import "./AudioCombiner.css"

const selectList = [
  { name: "File1 + File2", value: [0, 1] },
  { name: "File2 + File1", value: [1, 0] },
];

const blankNameList = [
  { name: "Start", value: "start", },
  { name: "Between", value: "0-1", },
  { name: "End", value: "end", },
];

const combinedAudioId = 'combined-audio';


function AudioCombinerCombine(props) {
  const {
    audioFiles,
    setAudioFiles,
    combinedFile,
    setCombinedFile,
    playSound,
    pauseSound,
    restartSound,
    getAudioFileData,
    widthScaleFactor,
    setWidthScaleFactor,
  } = props;

  const [t] = useTranslation("translation");

  // const visualizerRef = useRef(null)

  const [store, dispatch] = useStore();

  const [selectedIndexList, setSelectedIndexList] = useState(selectList[0].value);
  const [combinedAudioEl, setCombinedAudioEl] = useState();
  const [combinedCurrentTime, setCombinedCurrentTime] = useState(0);
  const [displayType, setDisplayType] = useState('wave');
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [selectedBlankPlace, setSelectedBlankPlace] = useState(blankNameList[0].value);
  const [blankDuration, setBlankDuration] = useState(0);
  const [showAddBlank, setShowAddBlank] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const fileCombineHandler = async (audioFiles, selectedIndexList) => {
    try {
      setIsLoading(true);

      await sleep(1000);
      
      const crunker = new Crunker();

      const combineBuffers = [];

      for (const selectedIndex of selectedIndexList) {
        let audioBuffer = audioFiles[selectedIndex].audioBuffer;

        if (audioFiles[selectedIndex].padStart > 0) {
          audioBuffer = crunker.padAudio(
            audioBuffer, 
            0, 
            audioFiles[selectedIndex].padStart,
          );
        }

        if (audioFiles[selectedIndex].padEnd > 0) {
          audioBuffer = crunker.padAudio(
            audioBuffer, 
            audioBuffer.duration, 
            audioFiles[selectedIndex].padEnd,
          );
        }

        combineBuffers.push(audioBuffer);

        // const paddedAudio = crunker.padAudio(audioBuffer, audioBuffer.duration, 5);
        // combineBuffers.push(paddedAudio);
      }

      // console.log(combineBuffers);

      // const crunker = new Crunker();

      const combinedBuffer = await crunker.concatAudio(combineBuffers);
      // const combinedBuffer = await crunker.mergeAudio(combineBuffers);
      
      // console.log(combinedBuffer);

      const type = 'audio/wav';

      const combinedBlob = await crunker.export(combinedBuffer, type);
      
      if (combinedBlob?.blob) {
        const combinedFile = new File([combinedBlob.blob], `combineAudio-${Date.now()}.wav`, {type: type});

        const combinedFileData = await getAudioFileData(combinedFile);
        
        setCombinedFile(combinedFileData);
      }

      setIsLoading(false);

    } catch(err) {
      console.log(err);
      setIsLoading(false);
      // throw err;
    }
  };

  const changeCombinedCurrentTime = (event) => { 
    setCombinedCurrentTime(event.target.currentTime);
  }

  const switchDisplayFormHandler = (type) => {
      setDisplayType(type);
  }

  const downloadCombinedFile = (file) => {
    // console.log(file)
    download(file, file.name, file.type);
  };


  const clearPadDurations = (audioFiles) => {
    const updatedAudioFiles = audioFiles.map(element => {
      return {
        ...element,
        padStart: 0,
        padEnd: 0,
      };
    });

    setAudioFiles(updatedAudioFiles);
  };


  useMemo(() => {
    if (combinedFile) {
      setCombinedAudioEl(
        <audio className='audioCombinerAudioEl'
          id={combinedAudioId}
          src={URL.createObjectURL(combinedFile.file)}
          controls
          onTimeUpdate={changeCombinedCurrentTime}
        />
      );
    }
  }, [combinedFile]);



  const containerHeight = 100;
  let waveHeight = 0;

  if (combinedFile) {
    waveHeight = combinedFile.bufferMax * containerHeight;
  }


  let audioCombinerCombineBody;

  if (audioFiles.length >= 2) {
    audioCombinerCombineBody = (
      <div>
        <div className='audioCombinerSection'>
          <div>
            <strong>
              {t('audioEditor06', 'Select file order')}
            </strong>
          </div>
          <div>
            <select className='selectBase'
              // name="pets" 
              // id="pet-select"
              defaultValue={selectList[0].value}
              onChange={(event) => { 
                setSelectedIndexList(JSON.parse(event.target.value));
                clearPadDurations(audioFiles);
              }}
            >
              {selectList.map(element => {
                return (
                  <option value={JSON.stringify(element.value)}>
                    {element.name}
                  </option>
                )
              })}
            </select>
          </div>
        </div>
        
        <div className='audioCombinerSection'>
          <span
            onClick={() => {
              setShowAddBlank(!showAddBlank);
            }}
          >
            {t('audioEditor07', 'Add blank audio')} {marks.triangleDown}
          </span>
          {showAddBlank && (
            <AudioCombinerAddPad 
              audioFiles={audioFiles}
              setAudioFiles={setAudioFiles}
              selectedBlankPlace={selectedBlankPlace} 
              setSelectedBlankPlace={setSelectedBlankPlace}
              blankDuration={blankDuration} 
              setBlankDuration={setBlankDuration}
              selectedIndexList={selectedIndexList}
              clearPadDurations={clearPadDurations}
            />
          )}
        </div>


        <div className='audioCombinerSection'>
          <button
            className='audioCombinerCombineButton'
            disabled={isLoading}
            onClick={() => { 
              fileCombineHandler(audioFiles, selectedIndexList); 
            }}
          >
            {t('audioEditor12', 'Combine audio files')}
          </button>
        </div>

        {isLoading && (
          <div><Loader /></div>
        )}
        
        {combinedFile && (
          <div className='audioCombinerSection'>
            <div style={{display: 'none'}}>
              {combinedAudioEl}
            </div>
            <div>
              <div>
                <span>
                  <strong>
                    Combined audio{' '}
                  </strong>
                  <span>
                    ({numeral(combinedFile.file.size).format('0.0 b')})
                    {' '}
                  </span>
                </span>
                <span>
                  <button className='audioCombinerListControlButton'
                    onClick={() => {
                      switchDisplayFormHandler('wave');
                    }}
                  >
                    {marks.barChartMark} (wave){' '}
                  </button>
                  <button className='audioCombinerListControlButton'
                    onClick={() => {
                      switchDisplayFormHandler('player');
                    }}
                  >
                    {marks.playMark} (player)
                  </button>
                </span>
              </div>
              {/* {displayType} */}
            </div>
            
            {displayType === 'player' && (
              <div style={{ padding: "1rem"}}>
              {combinedAudioEl}
              </div>
            )}
            {displayType === 'wave' && (
              <div>
                <div
                  style={{
                    height: containerHeight,
                    paddingTop: (containerHeight - waveHeight) / 2,
                    paddingBottom: (containerHeight - waveHeight) / 2
                  }}
                >
                  <AudioVisualizer
                    // ref={visualizerRef}
                    // ref={document.getElementById(audioFiles[0].id)}
                    blob={combinedFile.file}
                    width={combinedFile.audioBuffer.duration * 1 * widthScaleFactor}
                    height={combinedFile.bufferMax * containerHeight}
                    barWidth={1}
                    gap={0}
                    // barColor={'#f76565'}
                    barColor={'red'}
                    barPlayedColor={'gray'}
                    currentTime={combinedCurrentTime}
                    // currentTime={element.id === currentTime.id ? currentTime.currentTime : 0}
                  />
                </div>
                <div className='audioCombinerListControl'>
                  <button className='audioCombinerListControlButton'
                    title={'play'}
                    onClick={(event) => { playSound(event, combinedAudioId) }}
                  >
                    {marks.playMark}
                  </button>
                  <button className='audioCombinerListControlButton'
                    title={'pause'}
                    onClick={(event) => { pauseSound(event, combinedAudioId) }}
                  >
                    {marks.pauseMark}
                  </button>
                  <button className='audioCombinerListControlButton'
                    title={'restart'}
                    onClick={(event) => { restartSound(event, combinedAudioId) }}
                  >
                    {marks.shareMark}
                  </button>
                  <span>
                    {numeral(combinedCurrentTime).format('00:00')}
                  </span>
                </div>
              </div>
            )}

            <div className=''>
              <button className='btnBase'
                onClick={() => {
                  downloadCombinedFile(combinedFile.file);
                }}
              >
                {t('audioEditor20', 'Download file')}
                {' '}{marks.downloadMark}
              </button>
              <button className='btnBase'
                onClick={() => {
                  setShowUploadModal(true);
                  // createCombinedTextFile(context, question, output);
                }}
              >
                {t('audioEditor21', 'Upload file')}
                {' '}{marks.uploadMark} 
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }

  return (
    <Fragment>
      <div>
        {audioCombinerCombineBody}
      </div>

      {showUploadModal && (
        <div>
          <AudioCombinerFileUpload 
            uploadFile={combinedFile.file}
            setShowUploadModal={setShowUploadModal}
          />
        </div>
      )}
    </Fragment>
  )
}

export default AudioCombinerCombine;