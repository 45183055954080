import { Fragment, useEffect, useRef, useState, useMemo } from 'react'
import { useTranslation } from "react-i18next";
import { AudioVisualizer } from 'react-audio-visualize';
import Crunker from "crunker";
import numeral from "numeral";
import download from 'downloadjs';
// import _ from "lodash";

// import AudioCombinerFileUpload from './AudioMixerFileUpload.jsx';
// import Loader from '../../Loader/Loader.jsx';

import { useStore } from '../../../hook-store/store.js';
// import { decodeAudioBuffer } from '../AudioSlicer/audio-helper.ts';

import { marks } from '../../../utils/marks.jsx';
import "./AudioMixer.css"


const blankNameList = [
  { name: "Start", value: "start", },
  { name: "Between", value: "0-1", },
  { name: "End", value: "end", },
];


function AudioMixerAddPad(props) {
  const {
    audioFiles,
    setAudioFiles,
    selectedBlankPlace, 
    setSelectedBlankPlace,
    blankDuration, 
    setBlankDuration,
    selectedIndexList,
    clearPadDurations,
    selectedPadData, 
    setSelectedPadData,
  } = props;

  const [t] = useTranslation("translation");

  // const visualizerRef = useRef(null)

  const [store, dispatch] = useStore();

  const [isLoading, setIsLoading] = useState(false);

  // console.log(audioFiles)


  const setBlankDurationHandler = (event) => {
    if (Number(event.target.value) >= 0 && Number(event.target.value) <= 10) {
      setBlankDuration(Number(event.target.value))
    }
  };

  const setBlankDataHandler = (selectedKey, value, selectedPadData) => {
    setSelectedPadData({
      ...selectedPadData,
      [selectedKey]: value,
    });
  };

  const addBlankAudioHandler = (selectedPadData) => {
    const updateAudioFiles = audioFiles.map((element, index) => {
      if (index === selectedPadData.fileIndex) {
        return {
          ...element,
          padStart: selectedPadData.padStart,
          padEnd: selectedPadData.padEnd,
        };

      } else {
        return element;
      }
    });

    setAudioFiles(updateAudioFiles);
  };


  let audioCombinerAddPadBody;

  if (audioFiles.length >= 2) {
    audioCombinerAddPadBody = (
      <div>
        <div className='audioCombinerSection'>
            <div className='audioCombinerSection'>
              <div>
                <label>File</label>
              </div>
              <select className='selectBase'
                // name="pets" 
                // id="pet-select"
                defaultValue={0}
                onChange={(event) => { 
                  setBlankDataHandler(
                    'fileIndex',
                    Number(event.target.value),
                    selectedPadData,
                  );                  
                }}
              >
                {selectedIndexList.map(element => {
                  return (
                    <option value={element}>
                      File {element + 1}
                    </option>
                  );
                })}
              </select>
            </div>

            {/* <div>selected-pad-data {JSON.stringify(selectedPadData)}</div> */}
            {/* {selectedBlankPlace} */}
            <div className='audioCombinerSection'>
              <div>
                <label>{t('audioEditor14', 'Duration')}{' '}({t('audioEditor16', 'seconds')})</label>
              </div>
              <div className='audioMixerBlankDurationRow'>
                <span>
                  <span>{t('audioEditor08', 'Start')}: </span>
                  <input className='inputBase'
                    style={{width: "5rem"}}
                    type="text"
                    placeholder='0 ~'
                    onChange={(event) => { 
                      if (Number(event.target.value) >= 0) {
                        setBlankDataHandler(
                          'padStart',
                          Number(event.target.value),
                          selectedPadData,
                        )
                      }
                    }}
                  />
                </span>
                <span>
                  <span>{t('audioEditor10', 'End')}: </span>
                  <input className='inputBase'
                    style={{width: "5rem"}}
                    type="text"
                    placeholder='0 ~ '
                    onChange={(event) => { 
                      if (Number(event.target.value) >= 0) {
                        setBlankDataHandler(
                          'padEnd',
                          Number(event.target.value),
                          selectedPadData,
                        )
                      }
                    }}
                  />
                </span>
              </div>
            </div>

            <div className='audioCombinerSection'>
              <button className='btnBase'
                onClick={() => {
                  addBlankAudioHandler(
                    selectedPadData
                  );
                }}
              >
                {t('audioEditor07', 'Add blank audio')}
              </button>
            </div>

            <div className='audioCombinerSection'>
              <ul>
                {audioFiles.map((element, index) => {

                  const totalDuration = element.audioBuffer.duration + element.padStart + element.padEnd; 
                  
                  return (
                    <li key={element.id}>
                      <div>
                        <strong>
                          File {index + 1}{' '} 
                        </strong>
                        <span>
                          ({t('audioEditor08', 'Start')}: {element.padStart}sec,{' '}
                          {t('audioEditor10', 'End')}: {element.padEnd}sec,{' '}
                          {t('audioEditor17', 'Total')}: {totalDuration.toFixed(2)}sec)
                        </span>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>

            <div>
              <div className='audioCombinerSection'>
                <button className='btnBase'
                  onClick={() => { 
                    clearPadDurations(audioFiles);
                  }}
                >
                  {t('audioEditor11', 'Clear blank audio')}
                </button>
              </div>
            
            </div>
        </div>
      </div>
    );
  }

  return (
    <Fragment>
      <div>
        {audioCombinerAddPadBody}
      </div>
    </Fragment>
  )
}

export default AudioMixerAddPad;