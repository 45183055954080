import { Fragment, useState, useMemo } from 'react'
import { useTranslation } from "react-i18next";
import download from 'downloadjs';
import numeral from 'numeral';

import AudioSlicerFileUpload from './AudioSlicerFileUpload.jsx';

import { useStore } from '../../../hook-store/store.js';

import { marks } from '../../../utils/marks.jsx';
import './AudioSlicer.css';


function AudioSlicerReslult(props) {
  const {
    slicedAudioFile,
  } = props;

  const [t] = useTranslation("translation");

  const [store, dispatch] = useStore();

  const [showUploadModal, setShowUploadModal] = useState(false);

  const downloadSlicedFile = (file) => {
    download(file, file.name, file.type);
  };

  const resultAudioEl = useMemo(() => {
    if (slicedAudioFile) {
      return (
        <audio className='audioSlicerAudioEl'
          src={URL.createObjectURL(slicedAudioFile)}
          controls
        />
      );
    }
  }, [slicedAudioFile])

  let audioSlicerResultBody

  if (slicedAudioFile) {
    audioSlicerResultBody = (
      <div>
        <div className="audioSlicerSection">
          <div>
            <strong>
              Sliced Audio{' '} 
            </strong>
            <span>
              ({numeral(slicedAudioFile.size).format('0.0 b')})
            </span>
          </div>
          <div>
            {resultAudioEl}
            {/* <audio className='audioSlicerAudioEl'
              src={URL.createObjectURL(slicedAudioFile)}
              controls
            /> */}
          </div>
        </div>
        <div className=''>
          <button className='btnBase'
            onClick={() => {
              downloadSlicedFile(slicedAudioFile);
            }}
          >
            {t('audioEditor20', 'Download file')}
            {' '}{marks.downloadMark}
          </button>
          <button className='btnBase'
            onClick={() => {
              setShowUploadModal(true);
              // createCombinedTextFile(context, question, output);
            }}
          >
            {t('audioEditor21', 'Upload file')}
            {' '}{marks.uploadMark} 
          </button>
        </div>
 
      </div>
    );
  }
  

  return (
    <Fragment>
      <div>
        {audioSlicerResultBody}
      </div>

      {showUploadModal && (
        <div>
          <AudioSlicerFileUpload 
            uploadFile={slicedAudioFile}
            setShowUploadModal={setShowUploadModal}
          />
        </div>
      )}
    </Fragment>
  )
}

export default AudioSlicerReslult;