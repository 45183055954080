import { Fragment, useEffect, useRef, useState, useMemo } from 'react'
import { useTranslation } from "react-i18next";
import { AudioVisualizer } from 'react-audio-visualize';
import Crunker from "crunker";
import numeral from "numeral";
import download from 'downloadjs';
// import _ from "lodash";

import AudioCombinerFileUpload from './AudioCombinerFileUpload.jsx';
import Loader from '../../Loader/Loader.jsx';

import { useStore } from '../../../hook-store/store.js';
// import { decodeAudioBuffer } from '../AudioSlicer/audio-helper.ts';

import { marks } from '../../../utils/marks.jsx';
import "./AudioCombiner.css"



function AudioCombinerAddPad(props) {
  const {
    audioFiles,
    setAudioFiles,
    selectedBlankPlace, 
    setSelectedBlankPlace,
    blankDuration, 
    setBlankDuration,
    selectedIndexList,
    clearPadDurations,
  } = props;

  const [t] = useTranslation("translation");

  // const visualizerRef = useRef(null)

  const [store, dispatch] = useStore();

  const [isLoading, setIsLoading] = useState(false);

  // console.log(audioFiles)

  const blankNameList = [
    { name: `${t('audioEditor08', 'Start')}`, value: "start", },
    { name: `${t('audioEditor09', 'Between')}`, value: "0-1", },
    { name: `${t('audioEditor10', 'End')}`, value: "end", },
  ];

  const setBlankDurationHandler = (event) => {
    if (Number(event.target.value) >= 0 && Number(event.target.value) <= 10) {
      setBlankDuration(Number(event.target.value))
    }
  };

  const addBlankAudioHandler = (
    selectedBlankPlace, 
    blankDuration, 
    selectedIndexList
  ) => {

    const updatedAudioFiles = audioFiles.map((element, index) => {
      if (selectedBlankPlace === 'start') {
        if (index === selectedIndexList[0]) {
          return {
            ...element,
            padStart: blankDuration
          };
        } else {
          return element;
        }
      }

      if (selectedBlankPlace === 'end') {
        if (index === selectedIndexList[selectedIndexList.length -1]) {
          return {
            ...element,
            padEnd: blankDuration,
          };
        } else {
          return element;
        }
      }

      else {
        const padPlaceNum = Number( selectedBlankPlace.split('-')[0] );
        const padPlace = selectedIndexList[padPlaceNum];

        if (index === padPlace) {
          return {
            ...element,
            padEnd: blankDuration,
          };
        } else {
          return element;
        }
      }

    });

    setAudioFiles(updatedAudioFiles);
  };


  let audioCombinerAddPadBody;

  if (audioFiles.length >= 2) {
    audioCombinerAddPadBody = (
      <div>
        <div className='audioCombinerSection'>
            <div className='audioCombinerSection'>
              <div>
                <label>Blank place</label>
              </div>
              <select className='selectBase'
                // name="pets" 
                // id="pet-select"
                defaultValue={blankNameList[0].value}
                onChange={(event) => { 
                  setSelectedBlankPlace(event.target.value)
                }}
              >
                {blankNameList.map(element => {
                  return (
                    <option value={element.value}>
                      {element.name}
                    </option>
                  )
                })}
              </select>
            </div>
            {/* {selectedBlankPlace} */}
            <div className='audioCombinerSection'>
              <div>
                <label>{t('audioEditor15', 'Duration')} (0 ~ 10 {t('audioEditor16', 'seconds')})</label>
              </div>
              <input className='inputBase'
                style={{width: "5rem"}}
                type="text"
                placeholder='0 ~ 10'
                onChange={setBlankDurationHandler}
              />
            </div>
            <div className='audioCombinerSection'>
              <button className='btnBase'
                onClick={() => {
                  addBlankAudioHandler(
                    selectedBlankPlace, 
                    blankDuration,
                    selectedIndexList,
                  );
                }}
              >
                {t('audioEditor07', 'Add blank audio')}
              </button>
            </div>
            <div>
              <div className='audioCombinerSection'>
                {selectedIndexList.map((element, index) => {
                  // console.log(element)
                  if (index === 0) {
                    if ( selectedIndexList.length -2 === index) {
                      return (
                        <span>
                          {audioFiles[selectedIndexList[index]].padStart}sec{' '}
                          ~{' '} 
                          <strong>
                            File-{element + 1}{' '} 
                          </strong>
                          ~{' '}
                          {audioFiles[selectedIndexList[index]].padEnd}sec{' '} 
                          ~{' '}
                        </span>
                      );
                    } else {
                      return (
                        <span>
                          File-{element + 1} xxxx
                        </span>
                      );
                    }

                  }

                  if (index === selectedIndexList.length -1) {
                    return (
                      <span>
                        <strong>
                          File-{element + 1}{' '}
                        </strong>
                        ~{' '}
                        {audioFiles[selectedIndexList[index]].padEnd}sec
                      </span>
                    );
                  }
                  
                  else {
                    return (
                      <span>
                        file-{element + 1}, xxxx
                      </span>
                    );
                  }
                })}
              </div>

              <div className='audioCombinerSection'>
                <button className='btnBase'
                  onClick={() => { 
                    clearPadDurations(audioFiles);
                  }}
                >
                  {t('audioEditor11', 'Clear blank audio')}
                </button>
              </div>
            
            </div>
        </div>
      </div>
    );
  }

  return (
    <Fragment>
      <div>
        {audioCombinerAddPadBody}
      </div>
    </Fragment>
  )
}

export default AudioCombinerAddPad;