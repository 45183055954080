////  https://fontawesome.com/v4/icons/
// import classes from './marks.module.css';

export const marks = {
  checkMark: <strong  >&#9745;</strong>, //// ☑
  noCheckMark: <span  >&#9744;</span>, //// ☐
	
  fileMark: <i  className="fa fa-file-o"></i>,
  fileFillMark: <i  className="fa fa-file" aria-hidden="true"></i>,
  folderMark: <i  className="fa fa-folder-o"></i>,
  folderOpenMark: <i  className="fa fa-folder-open-o" aria-hidden="true"></i>,
  folderFillMark: <i  className="fa fa-folder"></i>,
  folderFillOpenMark: <i  className="fa fa-folder-open" aria-hidden="true"></i>,
  fileAudioOMark: <i  className="fa fa-file-audio-o" aria-hidden="true"></i>,
  filePdfOMark: <i  className="fa fa-file-pdf-o" aria-hidden="true"></i>,
  fileImageOMark: <i  className="fa fa-file-image-o" aria-hidden="true"></i>,
  fileVideoOMark: <i  className="fa fa-file-video-o" aria-hidden="true"></i>,
  fileTextOMark: <i  className="fa fa-file-text-o" aria-hidden="true"></i>,
  fileCodeOMark: <i  className="fa fa-file-code-o" aria-hidden="true"></i>,

  trashMark: <i  className="fa fa-trash" aria-hidden="true"></i>,
  editMark: <i  className="fa fa-pencil-square-o" aria-hidden="true"></i>,

  triangle: <span  >&#9652;</span>,
  triangleDown: <span  >&#9662;</span>,
  arrowCircleUp: <i  className="fa fa-arrow-circle-up"></i>,
  
  spinner: <i   className="fa fa-circle-o-notch fa-spin fa-1x fa-fw"></i>,
  
  // closeMark: <i className="fa fa-times" aria-hidden="true"></i>,
  closeMark: <span >&#x2715;</span>,

  uploadMark: <i  className="fa fa-upload" aria-hidden="true"></i>,
  downloadMark: <i   className="fa fa-download" aria-hidden="true"></i>,

  signInMrak: <i  className="fa fa-sign-in" aria-hidden="true"></i>,
  signOutMrak: <i  className="fa fa-sign-out" aria-hidden="true"></i>,
  userPlusMrak: <i  className="fa fa-user-plus" aria-hidden="true"></i>,
  userMrak: <i  className="fa fa-user" aria-hidden="true"></i>,
  usersMark: <i  className="fa fa-users" aria-hidden="true"></i>,

  gearMrak: <i  className="fa fa-cog" aria-hidden="true"></i>,
  bellMrak: <i  className="fa fa-bell" aria-hidden="true"></i>,

  copyMark: <i  className="fa fa-files-o" aria-hidden="true"></i>,
  moveMark: <i  className="fa fa-share" aria-hidden="true"></i>,
  replyMark: <i  className="fa fa-reply" aria-hidden="true"></i>,

  listUlMark: <i  className="fa fa-list-ul" aria-hidden="true"></i>,
  tableMark: <i  className="fa fa-table" aria-hidden="true"></i>,

  plusSquareOMark: <i  className="fa fa-plus-square-o" aria-hidden="true"></i>,
  plusSquareMark: <i  className="fa fa-plus-square" aria-hidden="true"></i>,
  dotCircleOMark: <i  className="fa fa-dot-circle-o" aria-hidden="true"></i>,

  externalLinkMark: <i  className="fa fa-external-link" aria-hidden="true"></i>,

  ellipsisVMark: <i  className="fa fa-ellipsis-v" aria-hidden="true"></i>,
  ellipsisHMark: <i  className="fa fa-ellipsis-h" aria-hidden="true"></i>,

  volumeUpMark: <i  className="fa fa-volume-up" aria-hidden="true"></i>,
  cloudUploadMark: <i  className="fa fa-cloud-upload" aria-hidden="true"></i>,
  cloudDownloadMark: <i  className="fa fa-cloud-download" aria-hidden="true"></i>,
  cloudMark: <i  className="fa fa-cloud" aria-hidden="true"></i>,
  infoCircleMark: <i  className="fa fa-info-circle" aria-hidden="true"></i>,

  exchangeMark: <i  className="fa fa-exchange" aria-hidden="true"></i>,

  shareMark: <i  className="fa fa-share" aria-hidden="true"></i>,
  playMark: <i  className="fa fa-play" aria-hidden="true"></i>,
  pauseMark: <i  className="fa fa-pause" aria-hidden="true"></i>,
  barChartMark: <i className="fa fa-bar-chart" aria-hidden="true"></i>,
  microphoneMark: <i className="fa fa-microphone" aria-hidden="true"></i>,
  arrowsHMark: <i className="fa fa-arrows-h" aria-hidden="true"></i>,
  arrowsVMark: <i className="fa fa-arrows-v" aria-hidden="true"></i>,
  plusMark: <i className="fa fa-plus" aria-hidden="true"></i>,
  minusMark: <i className="fa fa-minus" aria-hidden="true"></i>,

  scissorsMark: <i className="fa fa-scissors" aria-hidden="true"></i>,
  columnsMark: <i className="fa fa-columns" aria-hidden="true"></i>,
  compressMark: <i className="fa fa-compress" aria-hidden="true"></i>,
  randomMark: <i className="fa fa-random" aria-hidden="true"></i>,

};