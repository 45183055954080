import React, { Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useStore } from "../../hook-store/store";

import { kuraStorageLink } from '../../App';

import { marks } from "../../utils/marks";
import './AboutPage.css';
import './AboutPageAudioEditor.css';

const AboutPageAudioEditor = (props) => {

  const [t] = useTranslation("translation");

  const [store, dispatch] = useStore();

  return (
    <Fragment>
        <p>
          In <strong>Audio Editor,</strong> 
          {' '}
          {t('aboutAudioEditor01', 'user can edit audio files.')}
          <div className="paraSection">
            <strong>
              Audio Slicer {marks.scissorsMark} : 
            </strong>
            {' '}
            {t('aboutAudioEditor02', 'It is possible to trim or slice audio file.')}
            {' '}
            Audio Slicer{' '}
            {t('aboutAudioEditor03', 'has audio recorder. Recorded audio can be sliced after recording.')}
          </div>
          <div className="paraSection">
          <strong>
              Audio Combiner {marks.columnsMark} : 
            </strong>{' '}
            {t('aboutAudioEditor04', 'User can combine audio files.')}
          </div>
          <div className="paraSection">
          <strong>
              Audio Amplitude Changer {marks.volumeUpMark} : 
            </strong>{' '}
            {t('aboutAudioEditor05', 'User can change amplitude of audio file.')}
          </div>
          <div className="paraSection">
            <strong>
              Compress Audio File {marks.compressMark} : 
            </strong>{' '}
            {t('aboutAudioEditor06', 'User can change file format to MP3 and compress audio file.')}
          </div>
          <div className="paraSection">
            <strong>
              Audio Mixer {marks.randomMark} : 
            </strong>{' '}
            
            {t('aboutAudioEditor07', 'User can mix audio files. One example of mixed audio is audio with background sounds such as background music.')}
          </div>
          
          <div>
            {t('aboutPage.28', 'User can download the file or upload the file')} 
            {' '}to{' '}
            <a href={`${kuraStorageLink}/about`}
              target="_blank" rel="noopener noreferrer"
            >
              <strong>Kura Storage</strong>
            </a>. 
          </div>
        </p>
   

 

   
    </Fragment>
  );
};
export default AboutPageAudioEditor;
