import { Fragment, useEffect, useRef, useState, useMemo } from 'react'
import { useTranslation } from "react-i18next";
import { AudioVisualizer } from 'react-audio-visualize';
import Crunker from "crunker";
import numeral from "numeral";
import download from 'downloadjs';
// import _ from "lodash";

import AudioAmpChangerFileUpload from './AudioAmpChangerFileUpload.jsx';
import Loader from '../../Loader/Loader.jsx';

import { useStore } from '../../../hook-store/store.js';
import { changeAmplitude } from "../AudioSlicer/audio-helper.ts"
// import { decodeAudioBuffer } from '../AudioSlicer/audio-helper.ts';

import { marks } from '../../../utils/marks.jsx';
import "./AudioAmpChanger.css"

// import { Mp3Encoder } from 'lamejs';

const selectList = [
  { name: "File1 + File2", value: [0, 1] },
  { name: "File2 + File1", value: [1, 0] },
];

const ampChangedAudioId = 'amp-changed-audio';


function AudioAmpChangerChange(props) {
  const {
    audioFiles,
    combinedFile,
    setCombinedFile,
    playSound,
    pauseSound,
    restartSound,
    getAudioFileData,
    widthScaleFactor,
    setWidthScaleFactor,
  } = props;

  const [t] = useTranslation("translation");

  // const visualizerRef = useRef(null)

  const [store, dispatch] = useStore();

  const [combinedAudioEl, setCombinedAudioEl] = useState();
  const [combinedCurrentTime, setCombinedCurrentTime] = useState(0);
  const [displayType, setDisplayType] = useState('wave');
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [changeFactor, setChangeFactor] = useState(1);
  const [isLoading, setIsLoading] = useState(false);


  const changeAmplitudeHandler = async (
    audioBuffer, 
    start,
    end,
    changeFactor,
  ) => {
    try {
      setIsLoading(true);

      if (!(changeFactor >= 0.1 && changeFactor <= 3)) {
        return;
      }

      const changedAudioBuffer = changeAmplitude(
        audioBuffer, 
        start, 
        end, 
        changeFactor,
      );
      // console.log(combineBuffers);

      const crunker = new Crunker();

      const type = 'audio/wav';

      const changedBlob = crunker.export(changedAudioBuffer, type);
      
      if (changedBlob?.blob) {
        const combinedFile = new File([changedBlob.blob], `ampChangedAudio-${Date.now()}.wav`, {type: type});

        const combinedFileData = await getAudioFileData(combinedFile);
        
        setCombinedFile(combinedFileData);
      }

      setIsLoading(false);

    } catch(err) {
      console.log(err);
      setIsLoading(false);
      // throw err;
    }
  };

  const changeCombinedCurrentTime = (event) => { 
    setCombinedCurrentTime(event.target.currentTime);
  }

  const switchDisplayFormHandler = (type) => {
      setDisplayType(type);
  }

  const downloadCombinedFile = (file) => {
    // console.log(file)
    download(file, file.name, file.type);
  };

  const changeFactorInputHandler = (event) => {
    const changeFactor = Number(event.target.value)
    if (!(changeFactor >= 0.1 && changeFactor <= 3)) {
      return;
    }
    setChangeFactor(changeFactor);
  };


  useMemo(() => {
    if (combinedFile) {
      setCombinedAudioEl(
        <audio className='audioAmpChangerAudioEl'
          id={ampChangedAudioId}
          src={URL.createObjectURL(combinedFile.file)}
          controls
          onTimeUpdate={changeCombinedCurrentTime}
        />
      );
    }
  }, [combinedFile]);



  const containerHeight = 100;
  let waveHeight = 0;

  if (combinedFile) {
    waveHeight = combinedFile.bufferMax * containerHeight;
  }


  let audioAmpChangerChangeBody;

  if (audioFiles.length >= 1) {
    audioAmpChangerChangeBody = (
      <div>
        <div className='audioAmpChangerSection'>
          <div>
            <strong>Set change factor</strong>
          </div>

          <div  className='audioAmpChangerChangeFactorRow'>
            <div className='audioAmpChangerSliderRow'>
              <span>0.25</span>
              <input type="range" id="change-factor" name="change-factor" 
                min="0.25" max="3" step="0.25" 
                defaultValue="1"
                onChange={changeFactorInputHandler} 
              />
              <span>3</span>
            </div>
          </div>
          <div  className='audioAmpChangerChangeFactorRow'> 
            or, 
          </div>
          <div className='audioAmpChangerChangeFactorRow'>
            <input className='inputBase'
              style={{ width: "5rem" }}
              type="text" id="change-factor-text" name="change-factor-text" 
              placeholder='0.1 ~ 3'
              // value={changeFactor}
              onChange={changeFactorInputHandler} 
            />
          </div>
          
          <div className='audioAmpChangerChangeFactorRow'>
            change factor:{' '} 
            <strong>x {changeFactor}</strong>
          </div>
        </div>

        <div className='audioAmpChangerSection'>
          <button
            className='audioAmpChangerChangeButton'
            disabled={isLoading}
            onClick={() => { 
              changeAmplitudeHandler(
                audioFiles[0].audioBuffer,
                0,
                audioFiles[0].audioBuffer.length, 
                changeFactor
              ); 
            }}
          >
            {t('audioEditor13', 'Change audio amplitude')}
          </button>
        </div>

        {isLoading && (
          <div><Loader /></div>
        )}
        
        {combinedFile && (
          <div className='audioAmpChangerSection'>
            <div style={{display: 'none'}}>
              {combinedAudioEl}
            </div>
            <div>
              <div>
                <span>
                  <strong>
                    Changed audio{' '}
                  </strong>
                  <span>
                    ({numeral(combinedFile.file.size).format('0.0 b')})
                    {' '}
                  </span>
                </span>
                <span>
                  <button className='audioAmpChangerListControlButton'
                    onClick={() => {
                      switchDisplayFormHandler('wave');
                    }}
                  >
                    {marks.barChartMark} (wave){' '}
                  </button>
                  <button className='audioAmpChangerListControlButton'
                    onClick={() => {
                      switchDisplayFormHandler('player');
                    }}
                  >
                    {marks.playMark} (player)
                  </button>
                </span>
              </div>
              {/* {displayType} */}
            </div>
            
            {displayType === 'player' && (
              <div style={{ padding: "1rem"}}>
              {combinedAudioEl}
              </div>
            )}
            {displayType === 'wave' && (
              <div>
                <div
                  style={{
                    height: containerHeight,
                    paddingTop: (containerHeight - waveHeight) / 2,
                    paddingBottom: (containerHeight - waveHeight) / 2,
                    border: "0.1px solid lightgray",
                  }}
                >
                  <AudioVisualizer
                    // ref={visualizerRef}
                    // ref={document.getElementById(audioFiles[0].id)}
                    blob={combinedFile.file}
                    width={combinedFile.audioBuffer.duration * 1 * widthScaleFactor}
                    height={combinedFile.bufferMax * containerHeight}
                    barWidth={1}
                    gap={0}
                    // barColor={'#f76565'}
                    barColor={'red'}
                    barPlayedColor={'gray'}
                    currentTime={combinedCurrentTime}
                    // currentTime={element.id === currentTime.id ? currentTime.currentTime : 0}
                  />
                </div>
                <div className='audioAmpChangerListControl'>
                  <button className='audioAmpChangerListControlButton'
                    title={'play'}
                    onClick={(event) => { playSound(event, ampChangedAudioId) }}
                  >
                    {marks.playMark}
                  </button>
                  <button className='audioAmpChangerListControlButton'
                    title={'pause'}
                    onClick={(event) => { pauseSound(event, ampChangedAudioId) }}
                  >
                    {marks.pauseMark}
                  </button>
                  <button className='audioAmpChangerListControlButton'
                    title={'restart'}
                    onClick={(event) => { restartSound(event, ampChangedAudioId) }}
                  >
                    {marks.shareMark}
                  </button>
                  <span>
                    {numeral(combinedCurrentTime).format('00:00')}
                  </span>
                </div>
              </div>
            )}

            <div className=''>
              <button className='btnBase'
                onClick={() => {
                  downloadCombinedFile(combinedFile.file);
                }}
              >
                {t('audioEditor20', 'Download file')}
                {' '}{marks.downloadMark}
              </button>
              <button className='btnBase'
                onClick={() => {
                  setShowUploadModal(true);
                  // createCombinedTextFile(context, question, output);
                }}
              >
                {t('audioEditor21', 'Upload file')}
                {' '}{marks.uploadMark} 
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }

  return (
    <Fragment>
      <div>
        {audioAmpChangerChangeBody}
      </div>

      {showUploadModal && (
        <div>
          <AudioAmpChangerFileUpload 
            uploadFile={combinedFile.file}
            setShowUploadModal={setShowUploadModal}
          />
        </div>
      )}
    </Fragment>
  )
}

export default AudioAmpChangerChange;