import { Fragment, useState, useEffect } from 'react'
import { useTranslation } from "react-i18next";

// import { convert } from 'video-to-audio';

import { useStore } from '../../../hook-store/store.js';

import { marks } from '../../../utils/marks.jsx';
import './AudioSlicer.css';

function AudioSlicerControl(props) {
  const {
    setState,
    startTime,
    currentTime,
    widthFactor,
    heightFactor,
    xPadVal,
  } = props;

  const [t] = useTranslation("translation");

  const [store, dispatch] = useStore();

  // const [xPadVal, setXPadVal] = useState(0);

  useEffect(() => {

    const getX = () => {
      // setXPadVal(window.scrollX);
      setState({ xPadVal: window.scrollX });
      // console.log(window.scrollX)
    };

    const getXInterval = setInterval(() => {
      getX();
    }, 500);

    getX();

    return () => {
      clearInterval(getXInterval);
    }
  },[]);

  const handlePlayClick = () => {
    const audioEl = document.getElementById('audio-slicer-input-audio');
    if (audioEl) {
      setState({
        currentTime: currentTime,
        paused: false,
      });

      audioEl.play();
    }
  };
  
  const handlePauseClick = () => {
    const audioEl = document.getElementById('audio-slicer-input-audio');

    if (audioEl) {
      setState({
        paused: true,
      });

      audioEl.pause();
    }

  };

  const handleRestartClick = () => {
    const audioEl = document.getElementById('audio-slicer-input-audio');

    if (audioEl) {
      setState({
        currentTime: startTime,
        paused: false,
      });
    }
    audioEl.play();
  }

  const handleWaveExpand = () => {
    setState({
      widthFactor: widthFactor * 2
    });
  };

  const handleWaveShrink = () => {
    setState({
      widthFactor: widthFactor * 0.5
    });
  };

  const handleWaveTaller = () => {
    setState({
      heightFactor: heightFactor * 2 >=1 ? 1 : heightFactor * 2 
    });
  };

  const handleWaveSmaller = () => {
    setState({
      heightFactor: heightFactor * 0.5
    });
  };



  let audioSlicerControlBody

  audioSlicerControlBody = (
    <div style={{ 
            paddingLeft: xPadVal,
            paddingRight: 50,
            display: "flex",
            // flexWrap: "wrap",
            // width: "2000px",
            maxWidth: window.outerWidth,
          }}
    >
      <button
        type="button"
        className="ctrl-item"
        title="Play"
        onClick={handlePlayClick}
      >
        {marks.playMark}
      </button>
      <button
        type="button"
        className="ctrl-item"
        title="Pause"
        onClick={handlePauseClick}
      >
        {marks.pauseMark}
      </button>
      <button
        type="button"
        className="ctrl-item"
        title="Replay"
        onClick={handleRestartClick}
      >
        {marks.shareMark}
      </button>

      <button
        type="button"
        className="ctrl-item"
        onClick={handleWaveExpand}
      >
        {marks.arrowsHMark}
        <br/>
        {marks.plusMark}
      </button>
      <button
        type="button"
        className="ctrl-item"
        onClick={handleWaveShrink}
      >
        {marks.arrowsHMark}
        <br/>
        {marks.minusMark}
      </button>
        <button
          type="button"
          className="ctrl-item"
          style={heightFactor >= 1 ? {color: 'lightgray'} : null}
          disabled={heightFactor >= 1}
          onClick={handleWaveTaller}
        >
          {marks.arrowsVMark} 
          <br/>
          {marks.plusMark}
        </button>
      <button
        type="button"
        className="ctrl-item"
        onClick={handleWaveSmaller}
      >
        {marks.arrowsVMark}
        <br/>
        {marks.minusMark}
      </button>
    </div>
  )

  return (
    <Fragment>
      <div>
        <div id="audio-control-top"></div>
        <div>
          {audioSlicerControlBody}
        </div>
      </div>
    </Fragment>
  )
}

export default AudioSlicerControl;