import { Fragment, useRef, useState, useEffect } from 'react'
import { useTranslation } from "react-i18next";
// import _ from "lodash";

import Backdrop from '../../Backdrop/Backdrop.jsx';
import Loader from '../../Loader/Loader.jsx';
import Modal from '../../Modal/Modal.jsx';
import UploadedFileUseContent from '../../UploadedFileUseContent/UploadedFileUseContent.jsx';

import { useStore } from '../../../hook-store/store.js';

import { marks } from '../../../utils/marks.jsx';
import "./AudioConvertToMP3.css"

function AudioConvertToMP3SelectFile(props) {
  const {
    audioFiles,
    setAudioFiles,
    getAudioFileData,
  } = props;

  const [t] = useTranslation("translation");

  const hiddenFileSelect = useRef(null);

  const [store, dispatch] = useStore();
  const { 
    paramFileDataList,
  } = store.uiStore;

  const [isLoading, setIsLoading] = useState(false);
  const [showModalUseUploadedFile, setShowModalUseUploaded] = useState(false);


  useEffect(() => {
    if (audioFiles.length === 0 && paramFileDataList && paramFileDataList.files?.length > 0 &&
          paramFileDataList.openApp === 'audioConvertToMP3'
    ) {
      selectCombinerFilesHandler(paramFileDataList.files);
      dispatch('SET_PARAMFILEDATALIST', null);
    }

  },[paramFileDataList]);


  const handleInputClick = (event) => {
    hiddenFileSelect.current.click()
  };

  const selectCombinerFilesHandler = async (inputFiles) => {
    try { 
      // if (audioFiles.length >= 1) {
      //   return;
      // }

      setIsLoading(true);

      if (inputFiles.length > 0) {
        const files = [];

        for (const file of inputFiles) {
          const audioFileData = await getAudioFileData(file);
          
          files.push(audioFileData);
        }

        let newFiles

        if (audioFiles.length > 0) {
          newFiles = audioFiles.concat(files).slice(1,2);
        } else {
          newFiles = audioFiles.concat(files).slice(0,1);
        }
  
        setAudioFiles(newFiles);

        setIsLoading(false);
      }
    } catch(err) {
      console.log(err);
      setIsLoading(false);
      // throw err;
    }
  }

  let audioAmpChangerSelctFileBody

  audioAmpChangerSelctFileBody = (
    <div className='audioConvertToMP3SelectButtons'>
      <input 
        type='file'
        accept='audio/*'
        ref={hiddenFileSelect}
        style={{display:"none"}}
        onChange={(event) => {
          selectCombinerFilesHandler(event.target.files); 
        }}
        // multiple
      />
      <button className="btnBase"
        style={{border:"0.5px solid gray", borderRadius: "4px"}}
        disabled={audioFiles.length >= 2 || isLoading} 
        onClick={handleInputClick}
      >
        {/* {marks.fileTextOMark}{' '} */}
        {marks.fileMark}{' '}
        {t('audioEditor02', 'Import audio file')}
      </button>
      <button className="btnBase"
        style={{border:"0.5px solid gray", borderRadius: "4px"}}
        // disabled={isLoading} 
        onClick={() => { 
          setShowModalUseUploaded(true); 
        }}
      >
        {/* {marks.fileTextOMark}{' '} */}
        {marks.downloadMark}{' '}
        {t('audioEditor03', 'Uploaded file')} 
      </button>
    </div>
  )

  return (
    <Fragment>
      <div>
        {audioAmpChangerSelctFileBody}
      </div>
      {isLoading && (
        <Loader />
      )}
      {showModalUseUploadedFile && (
          <div>
            <Backdrop 
              zIndex={'95'}
              onCancel={() => { 
                setShowModalUseUploaded(false);
              }}
            />
            <Modal
              onClose={() => {
                setShowModalUseUploaded(false);
              }}
            >
              <UploadedFileUseContent
                setShowModalUseUploaded={setShowModalUseUploaded}
              />
            </Modal>
          </div>
        )}
    </Fragment>
  )
}

export default AudioConvertToMP3SelectFile;